import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import ReactDatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
import { FaFileCsv, FaFileExcel } from "react-icons/fa";
import { IoReloadCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../constants/axiosInstance";
import { checkSortOrderUpdate } from "../../constants/SortCheckUp";
import { logOut, updateLoading } from "../../store/actions/User";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { excelArr, filterRow } from "../../constants/ConstantData";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const Report = () => {
  const hitOrderLink = "get-orders";
  const hitVendorLink = "get-vendors";
  const hitStoreFrontLink = "get-vendor-storefronts";
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [lastSortType, setLastSortType] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [reports, setReports] = useState([]);
  const [csvDatas, setCsvDatas] = useState([]);
  const [storeFrontLoad, setStoreFrontLoad] = useState(false);

  const [vendors, setVendors] = useState([]);
  const [selectVendor, setSelectVendor] = useState({
    label: "Select Vendor",
    value: "",
  });
  const [storeFronts, setStoreFronts] = useState([]);
  const [selectStoreFront, setSelectStoreFront] = useState({
    label: "Select Store Front",
    value: "",
  });
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [count, setCount] = useState(0);
  const [csvLoad, setCsvLoad] = useState(false);
  const dispatch = useDispatch();
  const { user, access_token } = useSelector((state) => state.user);

  const [sortArr, setSortArr] = useState([
    { key: "id", value: "asc", active: false },
    { key: "vendor_name", value: "asc", active: false },
    { key: "sf_name", value: "asc", active: false },
    { key: "created_at", value: "asc", active: false },
    { key: "order_no", value: "asc", active: false },
    { key: "product_qnty", value: "asc", active: false },
    { key: "total_price", value: "asc", active: false },
    { key: "product_qnty", value: "asc", active: false },
    { key: "total_price", value: "asc", active: false },
  ]);
  const headers = [
    { label: "Order ID", key: "order_no" },
    { label: "Vendor Name", key: "vendor_name" },
    { label: "Storefront Name", key: "sf_name" },
    { label: "Transaction Date", key: "ts_date" },
    { label: "Product Quantity", key: "quantity" },
    { label: "Product Cost", key: "price" },
    { label: "Tips", key: "tip" },
    { label: "Service Fee", key: "service_fee" },
    { label: "Final Price", key: "final_price" },
    { label: "Square ID", key: "square_id" },
    { label: "Blockchain ID", key: "blockchain_id" },
    { label: "Smooth Profit", key: "smooth_profit" },
  ];

  let data = [];
  csvDatas.length > 0 &&
    csvDatas.map((report) => {
      return data.push({
        order_no: report.order_no,
        vendor_name: report.vendor_name,
        sf_name: report.sf_name,
        ts_date:
          moment(report.created_at).format("L") +
          " " +
          moment(report.created_at).format("LT"),
        quantity: report.product_qnty,
        price: "$" + report.total_price,
        tip: "$" + report.tip_amount,
        service_fee: "$" + report.service_fee,
        final_price: "$" + report.total_received,
        square_id: report.payment_id,
        blockchain_id: report.blockchain_tx1,
        smooth_profit: "$" + report.service_fee,
      });
    });

  useEffect(() => {
    getOrderList(
      rowsPerPage,
      page,
      "",
      "",
      user.role_id !== 13
        ? selectStoreFront?.value
        : user?.others_info?.store_fronts[0]?.id,
      startDateTime,
      endDateTime
    );
    getVendorList();
  }, []);

  useEffect(() => {
    getCsvDatas(
      1,
      50000,
      user.role_id !== 13
        ? selectStoreFront?.value
        : user?.others_info?.store_fronts[0]?.id,
      startDateTime,
      endDateTime,
      searchStr
    );
  }, [
    page,
    rowsPerPage,
    searchStr,
    selectVendor,
    selectStoreFront,
    startDateTime,
    endDateTime,
  ]);

  const getVendorList = async () => {
    const params = {
      limit: 50000,
      is_approved: 1,
      order_by: "id, desc",
    };
    await axiosInstance
      .get(hitVendorLink, { params, token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          setVendors(res.data.result.rows);
        }
      })
      .catch((error) => {});
  };

  const rowsChangingData = async (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
    getOrderList(
      e.target.value,
      0,
      "",
      searchStr,
      selectStoreFront?.value,
      startDateTime,
      endDateTime
    );
  };

  const pageChangingData = async (e, newPage) => {
    setPage(newPage);
    getOrderList(
      rowsPerPage,
      newPage,
      lastSortType,
      searchStr,
      selectStoreFront?.value,
      startDateTime,
      endDateTime
    );
  };

  const getCsvDatas = async (
    cPage,
    rowsPerPage,
    storefronts,
    startDate,
    endDate,
    search
  ) => {
    let params = {
      limit: rowsPerPage,
      page: cPage,
      search,
      storefronts,
      start_date:
        startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "",
    };
    if (startDate !== "" && endDate === "") {
      params = { ...params, start_date: "", end_date: "" };
    }

    await axiosInstance
      .get(hitOrderLink, { params, token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          setCsvDatas(res.data.result.rows);
        }
        setCsvLoad(false);
      })
      .catch((error) => {
        if (error?.code) {
          toast.error("Network Error.\r\n Please check internet");
        }
        if (error.response) {
          if (error?.response?.data?.result_code === 11) {
            dispatch(logOut());
          }
        }
        setCsvLoad(false);
      });
  };

  const getOrderList = async (
    rowsPerPage,
    cPage,
    orderBy,
    search,
    storefronts,
    startDate,
    endDate
  ) => {
    dispatch(updateLoading(true));
    let params = {
      limit: rowsPerPage,
      page: cPage + 1,
      order_by: orderBy,
      search,
      storefronts,
      start_date:
        startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "",
    };
    if (startDate !== "" && endDate === "") {
      params = { ...params, start_date: "", end_date: "" };
    }
    let abortController = new AbortController();
    await axiosInstance
      .get(hitOrderLink, { params, token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          let data = res.data.result;
          setReports(data.rows);
          setCount(data.total);
          let sort = orderBy.split(",");
          let newSort = checkSortOrderUpdate(sortArr, sort[0], sort[1]);
          setSortArr(newSort);
        }
        dispatch(updateLoading(false));
      })
      .catch((error) => {
        if (error?.code) {
          toast.error("Network Error.\r\n Please check internet");
        }
        if (error.response) {
          if (error?.response?.data?.result_code === 11) {
            dispatch(logOut());
          }
        }
        dispatch(updateLoading(false));
      });
    abortController.abort();
  };

  const searchOrderData = (e) => {
    setSearchStr(e.target.value);
    setPage(0);
    getOrderList(
      rowsPerPage,
      0,
      lastSortType,
      e.target.value,
      selectStoreFront?.value,
      startDateTime,
      endDateTime
    );
  };

  let vendorOpts = [];
  vendors.map((vendor) => {
    return vendorOpts.push({ label: vendor.vendor_name, value: vendor.id });
  });

  const getStoreFrontData = async (vendor_id) => {
    setStoreFrontLoad(true);
    let params = {
      limit: 50000,
      vendor_id,
      order_by: "id, desc",
    };

    await axiosInstance
      .get(hitStoreFrontLink, { params, token: access_token })
      .then((res) => {
        if (res.data.result_code === 0) {
          setStoreFronts(res.data.result.rows);
        }
        setStoreFrontLoad(false);
      })
      .catch((error) => {
        setStoreFrontLoad(false);
      });
  };

  let storeFrontOpts = [];
  storeFronts.map((storeFront) => {
    return storeFrontOpts.push({
      label: storeFront.sf_name,
      value: storeFront.id,
    });
  });

  const getStoreFrontOrder = (storefronts) => {
    getOrderList(
      rowsPerPage,
      page,
      lastSortType,
      searchStr,
      storefronts,
      startDateTime,
      endDateTime
    );
  };

  const orderChangeByStartDate = async (date) => {
    let store_Fronts = selectStoreFront.value;
    if (user.role_id === 13) {
      store_Fronts = user?.others_info?.store_fronts[0].id;
    }
    if (endDateTime !== "") {
      getOrderList(
        rowsPerPage,
        page,
        lastSortType,
        searchStr,
        store_Fronts,
        date,
        endDateTime
      );
    }
  };

  const orderChangeByEndDate = async (date) => {
    let store_Fronts = selectStoreFront.value;
    if (user.role_id === 13) {
      store_Fronts = user?.others_info?.store_fronts[0].id;
    }
    getOrderList(
      rowsPerPage,
      page,
      lastSortType,
      searchStr,
      store_Fronts,
      startDateTime,
      date
    );
  };

  const reloadReports = () => {
    getOrderList(10, 0, "", "", "", "", "");
    getVendorList();
    setStoreFronts([]);
    setStartDateTime("");
    setEndDateTime("");
    setSelectVendor({
      label: "Select Vendor",
      value: "",
    });
    setSelectStoreFront({});
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";

  const downloadExcelReport = () => {
    let xlsData = [];
    csvDatas.length > 0 &&
      csvDatas.map((report) => {
        return xlsData.push({
          "Order No": report.order_no,
          "Vendor Name": report.vendor_name,
          "Storefront Name": report.sf_name,
          Quantity: report.product_qnty,
          Price: "$" + report.total_price,
          Tip: "$" + report.tip_amount,
          "Service Fee": "$" + report.service_fee,
          "Final Price": "$" + report.total_received,
          "Square Id": report.payment_id,
          "Blockchain Id": report.blockchain_tx1,
          "Smooth Profit": "$" + report.service_fee,
        });
      });
    const ws = XLSX.utils.json_to_sheet(xlsData);
    headers.forEach((item, index) => {
      ws[excelArr[index] + "1"].s = {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "E2E8F0" },
          bgColor: { rgb: "E2E8F0" },
        },
        font: {
          name: "arial",
          sz: 14,
          bold: true,
          color: { rgb: "444" },
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true,
        },
        border: {
          top: { style: "medium", color: { rgb: "E2E8F0" } },
          bottom: { style: "medium", color: { rgb: "E2E8F0" } },
          left:
            index === 0 ? { style: "medium", color: { rgb: "E2E8F0" } } : {},
          right:
            index === headers.length - 1
              ? { style: "medium", color: { rgb: "E2E8F0" } }
              : {},
        },
      };
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      "Order_Report" + moment(new Date()).format("YMDHms") + ".xlsx"
    );
  };

  console.log("CSV Data: ", data);

  return (
    <>
      <Helmet>
        <title>Reports - Smooth Pay</title>
      </Helmet>

      <div className="adminBox">
        <div className="loginHeader relative">
          <div className="flex flex-row gap-4 place-items-center absolute top-2 right-2">
            <IoReloadCircle
              size={27}
              className="text-theme cursor-pointer"
              title="Reload"
              onClick={() => reloadReports()}
            />
            <CSVLink
              target={"_blank"}
              data={data}
              headers={headers}
              filename={
                "Order_Report-" + moment(new Date()).format("YMDHis") + ".csv"
              }
              title="CSV Export"
            >
              <FaFileCsv size={22} className="text-theme" />
            </CSVLink>

            <FaFileExcel
              size={22}
              onClick={() => {
                downloadExcelReport();
              }}
              className="text-theme cursor-pointer"
            />
          </div>
          <h1 className="mb-4">Order Reports</h1>
        </div>

        <div className="tablePlace">
          <Paper>
            <div className="flex flex-col md:flex-row gap-4 justify-between place-items-center mx-4">
              <div className="flex flex-col sm:flex-row gap-0 sm:gap-4 place-self-center">
                <ReactDatePicker
                  selected={startDateTime}
                  onChange={(date) => {
                    setStartDateTime(date);
                    orderChangeByStartDate(date);
                  }}
                  className="searchField"
                  placeholderText="Start Date"
                  isClearable={true}
                />
                <div className="flex place-self-center ">
                  <span>-</span>
                </div>
                <ReactDatePicker
                  selected={endDateTime}
                  onChange={(date) => {
                    setEndDateTime(date);
                    orderChangeByEndDate(date);
                  }}
                  className="searchField"
                  placeholderText="End Date"
                  isClearable={true}
                />
              </div>
              <div className="flex flex-col md:flex-row gap-4 justify-end w-full">
                {user.role_id !== 13 ? (
                  <Select
                    value={selectVendor}
                    defaultValue={selectVendor}
                    options={vendorOpts}
                    placeholder={{ label: "Select Vendor", value: "" }}
                    onChange={(option) => {
                      setSelectVendor(option);
                      setSelectStoreFront({
                        label: "Select Store Front",
                        value: "",
                      });
                      getStoreFrontData(option.value);
                    }}
                    className="max-w-[220px] w-full h-[38px] place-self-center listView"
                  />
                ) : (
                  ""
                )}

                <Select
                  value={
                    storeFrontLoad
                      ? {
                          label: "Loading",
                          value: "",
                        }
                      : selectStoreFront
                  }
                  defaultValue={selectStoreFront}
                  options={storeFrontOpts}
                  placeholder={{
                    label: "Select Store Front",
                    value: "",
                  }}
                  onChange={(option) => {
                    setSelectStoreFront(option);
                    getStoreFrontOrder(option.value);
                  }}
                  isLoading={storeFrontLoad}
                  className="max-w-[220px] w-full h-[38px] place-self-center listView"
                />
                <div className="searchBox">
                  <input
                    placeholder="Search here"
                    value={searchStr}
                    onChange={searchOrderData}
                    className="searchField"
                  />
                </div>
              </div>
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel>Order No</TableSortLabel>
                    </TableCell>
                    {user.role_id === 11 || user.role_id === 14 ? (
                      <TableCell>
                        <TableSortLabel>Storefront Name</TableSortLabel>
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {user.role_id !== 11 &&
                    user.role_id !== 13 &&
                    user.role_id !== 14 ? (
                      <>
                        <TableCell>
                          <TableSortLabel>Vendor Name</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel>Storefront Name</TableSortLabel>
                        </TableCell>
                      </>
                    ) : (
                      ""
                    )}

                    <TableCell>
                      <TableSortLabel>Transaction Date</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Quantity</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Product Total</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Tips</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Service Fee</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Total</TableSortLabel>
                    </TableCell>
                    {user.role_id !== 13 && user.role_id !== 14 ? (
                      <>
                        <TableCell>
                          <TableSortLabel>Square ID</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel>Blockchain ID</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel>Smooth Profit</TableSortLabel>
                        </TableCell>
                      </>
                    ) : (
                      ""
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports.length > 0 ? (
                    reports.map((report, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{report.order_no}</TableCell>
                          {user.role_id === 11 || user.role_id === 14 ? (
                            <>
                              <TableCell>{report.sf_name}</TableCell>
                            </>
                          ) : (
                            ""
                          )}
                          {user.role_id !== 11 &&
                          user.role_id !== 13 &&
                          user.role_id !== 14 ? (
                            <>
                              <TableCell>{report.vendor_name}</TableCell>
                              <TableCell>{report.sf_name}</TableCell>
                            </>
                          ) : (
                            ""
                          )}
                          <TableCell>
                            <b>
                              {moment(report.created_at).format("L") +
                                " " +
                                moment(report.created_at).format("LT")}
                            </b>
                          </TableCell>
                          <TableCell>{report.product_qnty}</TableCell>
                          <TableCell>${report.total_price}</TableCell>
                          <TableCell>${report.tip_amount}</TableCell>
                          <TableCell>${report.service_fee}</TableCell>
                          <TableCell>${report.total_received}</TableCell>
                          {user.role_id !== 13 && user.role_id !== 14 ? (
                            <>
                              <TableCell>{report.payment_id}</TableCell>
                              <TableCell>{report.blockchain_tx1}</TableCell>
                              <TableCell>${report.service_fee}</TableCell>
                            </>
                          ) : (
                            ""
                          )}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} className="!text-center">
                        No Report Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                {reports && reports.length > 0 ? (
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={filterRow}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onRowsPerPageChange={rowsChangingData}
                        onPageChange={pageChangingData}
                      />
                    </TableRow>
                  </TableFooter>
                ) : (
                  ""
                )}
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default Report;
